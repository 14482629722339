<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>FAQ category</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \ FAQ category
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    @click="showModal(null)"
                    class="mt-1 btn btn-block btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    new
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    v-on:keyup.enter="getFaqCategory"
                    outlined
                    v-model="search.title"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    :items="active_statuses"
                    clearable
                    item-text="title"
                    item-value="value"
                    v-model="search.active"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    @click.prevent="getFaqCategory"
                    v-on:keyup.enter="getFaqCategory"
                    class="btn btn-primary w-35"
                    style="color: #fff"
                    :loading="loading"
                  >
                    <v-icon small outlined>fas fa-search</v-icon>&nbsp; Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Status</th>
                    <th class="px-1">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(faq, index) of faqs" :key="faq.title">
                    <td class="wrap-column px-3">{{ faq.title }}</td>
                    <td class="wrap-column px-3 pt-3">
                      <span
                        class="badge text-lg`"
                        v-bind:class="{
                          'badge-success': faq.is_active,
                          'badge-danger': !faq.is_active,
                        }"
                        >{{ faq.is_active ? "Active" : "Inactive" }}</span
                      >
                    </td>
                    <td class="pr-0">
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i> </span
                          ></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <!--                                                  <b-dropdown-text tag="div" class="navi-item">-->
                          <!--                                                    <a href="#" class="navi-link" @click="detail(faq.id)">-->
                          <!--                                                            <span class="navi-icon">-->
                          <!--                                                              <i class="fas fa-eye"></i>-->
                          <!--                                                            </span>-->
                          <!--                                                      <span class="navi-text"> View </span>-->
                          <!--                                                    </a>-->
                          <!--                                                  </b-dropdown-text>-->
                          <b-dropdown-text tag="div" class="navi-item">
                            <a href="#" class="navi-link" @click="editfaq(faq)">
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item">
                            <a href="#" class="navi-link" @click="deleteFaq(faq.id)">
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                  <tr v-if="faqs.length == 0">
                    <td class="text-center font-weight-bold px-3" colspan="3">
                     <strong>
                       No data available to display.
                     </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-if="faqs.length > 0"
                class="pull-right mt-7"
                @input="getFaqCategory"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{ `${edit ? "Update" : "Add"} FAQ category` }}
            <hr />
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon @click="cancelForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Title" v-model="faq.title" outlined dense>
                </v-text-field>
                <span class="text-danger" v-if="$v.faq.title.$error"
                  >This information is required</span
                >
              </v-col>
              <v-col cols="12">
                Status
                <v-switch
                  v-model="faq.is_active"
                  :label="faq.is_active ? 'Active' : 'Inactive'"
                ></v-switch>
              </v-col>

              <!--                        <v-col cols="2">
                            <button class="btn btn-primary" @click="createOrUpdate"> {{edit? 'Edit' :'Add'}}</button>
                        </v-col>-->
            </v-row>
            <v-row>
              <!--                        <table class="table mt-3">
                            <thead class="text-left">
                            <tr class="px-3">
                              <th>Title</th>
                              <th>Action</th>
                            </tr>
                            </thead>


                            <tr v-for="(faq, index) of faqs" :key="faq.title">
                                <td scope="row">{{ faq.title }}&nbsp;&nbsp;
                                    <i class="fas fa-circle"
                                       :class="faq.is_active?'dot-active':'dot-inactive'"></i></td>

                                <td>
                                    <b-dropdown
                                            size="sm"
                                            variant="link"
                                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            no-caret
                                            right
                                            no-flip
                                    >
                                        <template v-slot:button-content>
                                            <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                                        </template>
                                        &lt;!&ndash;begin::Navigation&ndash;&gt;
                                        <div class="navi navi-hover ">

                                            &lt;!&ndash;                                                  <b-dropdown-text tag="div" class="navi-item">&ndash;&gt;
                                            &lt;!&ndash;                                                    <a href="#" class="navi-link" @click="detail(faq.id)">&ndash;&gt;
                                            &lt;!&ndash;                                                            <span class="navi-icon">&ndash;&gt;
                                            &lt;!&ndash;                                                              <i class="fas fa-eye"></i>&ndash;&gt;
                                            &lt;!&ndash;                                                            </span>&ndash;&gt;
                                            &lt;!&ndash;                                                      <span class="navi-text"> View </span>&ndash;&gt;
                                            &lt;!&ndash;                                                    </a>&ndash;&gt;
                                            &lt;!&ndash;                                                  </b-dropdown-text>&ndash;&gt;
                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a href="#" class="navi-link" @click="editfaq(faq)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                                    <span class="navi-text"> Edit </span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a href="#" class="navi-link"
                                                   @click="deleteFaq(faq.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                                                    <span class="navi-text">  Delete</span>
                                                </a>
                                            </b-dropdown-text>

                                        </div>
                                        &lt;!&ndash;end::Navigation&ndash;&gt;
                                    </b-dropdown>

                                </td>
                            </tr>

                        </table>-->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn btn-standard cancel-btn" depressed @click="cancelForm">
            Cancel
          </v-btn>
          <v-btn
            class="text-white ml-2 btn btn-primary"
            depressed
            @click="createOrUpdate"
            :loading="isBusy"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import FaqService from "@/core/services/faq/FaqService";

const faqService = new FaqService();
export default {
  validations: {
    faq: {
      title: { required },
    },
  },
  data() {
    return {
      search: {
        active: "1",
        title: null,
        is_parent: 1,
      },
      dialog: false,
      loading: false,
      edit: false,
      sizeExceed: false,
      isBusy: false,
      faq: {
        title: null,
        is_parent: 1,
        is_active: true,
      },
      faqs: [],
      total: null,
      perPage: null,
      page: null,
      active_statuses: [
        { title: "Active", value: "1" },
        { title: "Inactive", value: "0" },
      ],
    };
  },
  mounted() {
    this.getFaqCategory();
  },
  methods: {
    cancelForm(){
      this.resetForm()
      this.hideModal()
    },

    getFaqCategory() {
      this.loading = true;
      faqService
        .paginate(this.search, this.page)
        .then((response) => {
          this.faqs = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
        });
    },
    showModal(id = null) {
      if (id) {
        this.edit = true;
        this.getFaq(id);
      }else{
        this.resetForm()
      }
      this.dialog = true;
      this.getFaqCategory();
      // this.$refs["my-modal"].show();
    },
    hideModal() {
      this.dialog = false;
    },
    getFaq(id) {
      faqService.show(id).then((response) => {
        this.faq = response.data.faq;
        if (this.faq.tags) {
          this.faq.tags = this.faq.tags.split(",");
        }
      });
    },
    editfaq(item) {
      this.edit = true;
      this.dialog = true;
      this.faq = item;
    },
    generateFd() {
      // console.log(this.faq)
      let fd = new FormData();
      for (let key in this.faq) {
        if (key == "is_active" && this.faq["is_active"]) {
          fd.append("is_active", this.faq ? 1 : 0);
        } else {
          if (this.faq[key] != null) {
            fd.append(key, this.faq[key]);
          }
        }
      }
      return fd;
    },
    createOrUpdate() {
      this.$v.faq.$touch();
      if (this.$v.faq.$error) {
        setTimeout(() => {
          this.$v.faq.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateFaqCategory();
        } else {
          this.createFaqCategory();
        }
      }
    },
    updateFaqCategory() {
      this.isBusy = true;
      faqService
        .update(this.faq.id, this.faq)
        .then((response) => {
          this.isBusy = false;
          this.$snotify.success("Information updated");
          this.getFaqCategory();
          this.resetForm();
          this.hideModal();
          this.$emit("refresh_faq");
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
          // console.log(error);
        });
    },

    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      if (mbSize > 20) {
        this.sizeExceed = true;
      } else {
        this.sizeExceed = false;
      }
    },
    deleteFaq(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            faqService
              .delete(id)
              .then((response) => {
                this.getFaqCategory();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    createFaqCategory() {
      this.isBusy = true;
      faqService
        .store(this.faq)
        .then((response) => {
          this.isBusy = false;
          this.resetForm();
          this.getFaqCategory();
          this.hideModal();
          this.$emit("refresh_faq");
          this.$snotify.success("Information added");
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
          // console.log(error);
        });
    },
    resetForm() {
      this.$v.faq.$reset();
      this.edit = false;
      this.faq = {
        title: null,
        is_active: 1,
        is_parent: 1,
      };
    },
  },
};
</script>
